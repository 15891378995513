import * as React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import SoftwareSec from "../components/software/software.js"
import Hero from "../components/misc/hero.js"

const SoftwareAccess = () => (
  <div className="contactPage softwareAccess">
    <Layout>
      <Seo title="Software Access" />
      <div className="contactSecWrapper">
        <Hero />
        <SoftwareSec />
      </div>
    </Layout>
  </div>
)

export default SoftwareAccess

