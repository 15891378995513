import React, { useState } from "react"
import Select from "react-select"

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    border: "1px solid #fff",
    color: "red",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#bf1116" : "#bf1116",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#bf1116" : "#bf1116",
    },
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #fff",
    // kill the gap
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    background: "#151515",
    color: "#bf1116",
  }),
}

const options = [
  { value: "English", label: "English" },
  { value: "French", label: "French" },
]

const SoftwareSec = ({ data }) => {
   const [values, setValues] = useState({
    name: "",
    email: "",
    language: "",
    jobTitle: "",
    company: "",
    city: "",
    phoneNumber: "",
  })
  const [errorContact, setError] = useState("")
  const [successContact, setSuccessContact] = useState(false)

  const { name, email, language, jobTitle, company, city, phoneNumber } = values

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChange = selectedOption => {
    setValues({ ...values, language: selectedOption })
    // console.log(`Option selected:`, selectedOption);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setError("")
    setSuccessContact(false)

    if (!language.value || !name || !email || !jobTitle || !company || !city || !phoneNumber ) {
      setError("All fields are required")
      return
    }

    if (!validateEmail(email)) {
      setError("Please provide a valid email")
      return
    }
    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccessContact(true)
        document.querySelector('.contactSec form').classList.add("formSubmitted");
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }
  return (
    <section className="contactSec">
      <div className="leftSideBar">
        <div className="upper-col">
          <h1 className="en-only">To request access to the COMMB Software please fill out the contact form.</h1>
          <h1 className="fr-only">Veuillez remplir le formulaire pour demander l'accès au NavigateurCOMMBMD et/ou au Rapport de données COMMB.</h1>
        </div>
        <div className="lower-col">
        </div>
      </div>
      <form onSubmit={handleSubmit} className="software-access">
        <Select
          options={options}
          className={`customSelect custom-option contactDropdown`}
          styles={customStyles}
          classNamePrefix={`contactDropdown`}
          required
          placeholder={`Language: *`}
          name="language"
          value={language}
          onChange={onChange}
        />
        <label className="hidden">Language</label>
        <input
          type="text"
          className="hidden"
          name="language"
          value={language.value}
        />
        <input
          type="text"
          placeholder="Name *"
          required
          className="textInput"
          name="name"
          value={name}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Email *"
          required
          className="textInput"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Job Title: *"
          required
          className="textInput"
          name="jobTitle"
          value={jobTitle}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Company: *"
          required
          className="textInput"
          name="company"
          value={company}
          onChange={handleChange}
        />        
        <input
          type="text"
          placeholder="City: *"
          required
          className="textInput"
          name="city"
          value={city}
          onChange={handleChange}
        />     
        <input
          type="text"
          placeholder="Phone Number: *"
          required
          className="textInput"
          name="phoneNumber"
          value={phoneNumber}
          onChange={handleChange}
        />     
         <button className="submit"><span className="submitText">Submit</span>
                {successContact && ( <span className="thankYouMsg">
                    Thank you for contacting us! We'll be in touch
            with you soon.</span>)}
        </button>
      </form>



    </section>
  )
}

export default SoftwareSec
